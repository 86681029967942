export class SignupAddress {
  static attachEvents(){
    $('#search_again').on('click', SignupAddress.searchAgain);
    $('#manual_address').on('click', SignupAddress.manualAddress);
  }

  static postcodeLookup(api_key) {
    $('#postcode_lookup').getAddress({
      api_key: api_key,
      output_fields: {
        line_1: '#user_address_line1',
        line_2: '#user_address_line2',
        post_town: '#user_address_town',
        county: '#user_address_county',
        postcode: '#user_address_postcode'
      },
      onLookupSuccess: function(){
        $('a, input, button', '#postcode_lookup').hide();
        $('#address_not_listed').show();
      },
      onAddressSelected: function(elem, index){
        SignupAddress.showAddressForm();
        $('#address_not_listed').hide();
      },
      onManualAddress: function(){
        SignupAddress.showAddressForm();
      }
    });
  }

  //

  static showAddressForm() {
    $('#enter_address, .actions').removeClass('hide');
    $('#postcode_lookup').hide();
  }

  static searchAgain(e) {
    e.preventDefault();
    $('#address_not_listed').hide();
    $('a, input, button', '#postcode_lookup').show();
    $('select', '#postcode_lookup').hide();
  }

  static manualAddress(e) {
    e.preventDefault();
    $('#address_not_listed').hide();
    $('#user_address_postcode').val($('input', '#postcode_lookup').val());

    SignupAddress.showAddressForm();
  }
}
