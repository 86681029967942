export class BrandSelector {
  static init() {
    // set initial state
    $('.brands label').each(function(idx, el){
      BrandSelector.toggleSelected(el);
    });

    // toggle on change
    $('.brands input:checkbox').change(function(e){
      BrandSelector.toggleSelected($(this).closest('label'));
    })

    // hide excess brands
    let brands_to_show = 24;

    if ($('.brands > li').length > brands_to_show) {
      $('.brands > li').slice(brands_to_show).hide();

      $('.show-all-brands').click(function(e) {
        e.preventDefault();
        $('.brands > li').show();
        $(this).hide();
      });

    } else {
      $('.show-all-brands').hide();
    }
  }

  static toggleSelected(element) {
    $('img', element).toggleClass('selected', $('input:checkbox', element).is(':checked'));
  }
}
