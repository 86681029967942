export class RoutineBuilder {

  static updateSelectList(select) {
    const ids = $('input', select.closest('.routine')).map(function() {
      return $(this).val();
    }).get();

    $('option', select).attr('disabled', false);
    ids.forEach(function(id) {
      $('option[value="' + id + '"]', select).attr('disabled', true);
    });
  }

  static init() {
    $('.add-another select').on('change', function() {
      const val = $(this).val();
      const text = $(this).find('option:selected').text();

      const li = $($('#routine_item_template').html());

      $('span.name', li).text(text);
      const input = $('input', li);

      input.attr(
        'name',
        input.attr('name').replace('[time]', '[' + $(this).attr('id') + ']'),
      );
      input.val(val);

      li.insertBefore($(this).parent());

      $(this).val('');

      RoutineBuilder.updateSelectList($(this));
    });

    $('.routine').on('click', '.remove', function() {
      const routine = $(this).closest('.routine');
      $(this).closest('li').remove();
      RoutineBuilder.updateSelectList($('select', routine));
    });

    RoutineBuilder.updateSelectList($('#morning'));
    RoutineBuilder.updateSelectList($('#daytime'));
    RoutineBuilder.updateSelectList($('#evening'));
  }
}
