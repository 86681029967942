export class Util {

	static format_postcode(code_input) {
		let code = code_input.value.toUpperCase();
		let re = /^\s*([A-Z]{1,2}[\dA-Z]{1,2})\s*(\d[A-Z]{2})\s*$/i;
		let match = code.match(re);

		if (match !== null) {
			code = match[1] + " " + match[2]
		}

		code_input.value = code
	}

	static auto_format_postcode_inputs() {
		document.querySelectorAll('[data-post-code]').forEach(function (el) {
			el.addEventListener('blur', function (e) {
				Util.format_postcode(this)
			})
		})
	}
}
