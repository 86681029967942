import 'jquery'
import Rails from 'rails-ujs';
import 'foundation-sites'
import '../shared/js/get_address'
import autosize from 'autosize';

const Turbolinks = require("turbolinks");

import {BrandSelector} from '../shared/js/brand_selector';
import {RoutineBuilder} from '../shared/js/routine-builder'
import {SignupAddress} from './js/signup_address';
import {Util} from '../shared/js/util'

import './index.scss'

const images = require.context('../images', true);
const fonts = require.context('../fonts', true);

window.$ = window.jQuery = require("jquery");


Rails.start();
Turbolinks.start();

window.BrandSelector = BrandSelector;
window.RoutineBuilder = RoutineBuilder;
window.SignupAddress = SignupAddress;
window.Util = Util;


document.addEventListener("turbolinks:load", function () {
	$(document).foundation();
	autosize(document.querySelectorAll('textarea'));
	Util.auto_format_postcode_inputs();
});

